body {
    font-size: 16px;
}

/* article-list.js */
.article-list {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 15px;
    max-width: 100%;
}

.base-card {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    height: fit-content;
    margin: 1%;
}

.base-card-img {
    margin: 1.5%;
    height: fit-content;
    width: 97%;
}

.base-card-author {
    font-size: medium;
    font-weight: 300;
}

.pagination {
    display: flex;
    justify-content: center;
}

.page-button {
    margin: 10px;
}
/* --------------------------------------- */

/* footer-bar.js */
.footer-bar {
    justify-content: center;
    flex-direction: column;
}

.site-info {
    color: #fff;
    font-size: small;
}

.social-container {
    margin-bottom: 25px;
    margin-top: 15px;
}

/* --------------------------------------------- */

/* header-bar.js */
.header-bar {
    justify-content: center;
    position: sticky;
    top: 0;
    max-width: 100%;
}

.nav-link {
    color: #ffffff !important;
    text-align: center;
}

.bar-wrapper {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 100%;
}
/* ------------------------------------------ */

/* single-article.js */
.single-article {
    max-width: 100%;
    height: fit-content;
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.article-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: start;
}

.article-card-title {
    font-size: xx-large;
    text-align: center;
    max-width: 100%;
}

.article-card-author {
    font-size: small;
    font-weight: 300;
    text-align: center;
}

.article-card-img {
    height: fit-content;
    margin: 12px;
    max-width: calc(100% - 24px);
}

.card-text {
    max-width: 100%;
}

.card-body {
    max-width: 100%;
}
/* ---------------------------------------- */

/* vertical-ad.js*/
.vertical-sp-card {
    width: 100%;
    height: fit-content;
}

.vertical-sp-title {
    text-align: center;
}

.single-sp {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1%;
}

/* App.js */
.content-container {
    min-height: calc(100vh - 90px);
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin: auto;
    max-width: 1000px;
}

.inner-content-container {
    max-width: 70%;
}

.wrapper {
    max-width: 100vw;
    margin: auto;
}

.sp-container {
    margin-top: 2.5%;
    max-width: 30%;
}
/* ---------------------------------------------------------------- */

/* Mobile */

@media (max-width: 768px) {
    body {
        font-size: .9rem;
    }

    .base-card {
        flex-direction: column;
        max-width: 100%;
    }

    .content-container {
        flex-direction: column;
        max-width: 100%;
    }

    .inner-content-container {
        max-width: 100%;
    }

    .sp-container {
        max-width: 100%;
        margin: 1.5%;
    }
}